import { type ComponentProps } from "react";

import { cn } from "@/utils/cn.ts";

type BurgerIconProps = Omit<ComponentProps<"button">, "aria-expanded"> & {
  isActive: boolean;
  label?: string;
};

export const BurgerIcon = ({
  isActive,
  className,
  label = "Toggle menu",
  ...rest
}: BurgerIconProps) => {
  return (
    <button
      type="button"
      aria-expanded={isActive}
      aria-label={label}
      className={cn(
        "relative w-8 h-6 cursor-pointer flex flex-col justify-between items-center",
        className,
      )}
      {...rest}
    >
      <span
        className={cn(
          "block h-1 w-full bg-current transform transition-transform duration-300 ease-in-out",
          isActive ? "rotate-45 translate-y-2.5" : "rotate-0 translate-y-0",
        )}
        style={{ viewTransitionName: "burger-menu-top" }}
      />
      <span
        className={cn(
          "block h-1 w-full bg-current transform transition-opacity duration-300 ease-in-out",
          isActive ? "opacity-0" : "opacity-100",
        )}
        style={{ viewTransitionName: "burger-menu-middle" }}
      />
      <span
        className={cn(
          "block h-1 w-full bg-current transform transition-transform duration-300 ease-in-out",
          isActive ? "-rotate-45 -translate-y-2.5" : "rotate-0 translate-y-0",
        )}
        style={{ viewTransitionName: "burger-menu-bottom" }}
      />
    </button>
  );
};
